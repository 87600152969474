export function update_triggering_fields() {
  function update_triggering_values(target) {
    var parent_row = target.closest(".nested-field");
    var hidden_select = parent_row.find("select#fields_form_triggering_values");
    var triggering_values_box = hidden_select[0].selectize;

    function update_triggering_values_box(data) {
      triggering_values_box.clear();
      triggering_values_box.clearOptions();
      $.each(data, function (index, row) {
        triggering_values_box.addOption({
          value: row[0],
          text: row[1]
        });
      });
    }

    $.ajax({
      type: 'get',
      url: '/search/criterion_from_fields',
      data: {id: target.val()},
      dataType: 'json',
      success: update_triggering_values_box
    });
  }

  $("#fields_form_triggering_fields_form_id").on("change", function(event) {
    var target = $(event.target);
    update_triggering_values(target);
  });
}