// NOTES: commented out for now since we need to figure out how to use datetimepicker with vite

// $('.date-picker').datetimepicker({
//   format: '<%= rb_time_format_to_js_time_format(Settings["format.date"]) %>',
//   formatDate: '<%= rb_time_format_to_js_time_format(Settings["format.date"]) %>',
//   timepicker: false,
//   scrollInput: false
// }).attr("autocomplete", "off");

// $('.datetime-picker').datetimepicker({
//   format: '<%= rb_time_format_to_js_time_format(Settings["format.date"]) %> <%= rb_time_format_to_js_time_format(Settings["format.time"]) %>',
//   formatTime: '<%= rb_time_format_to_js_time_format(Settings["format.time"]) %>',
//   formatDate: '<%= rb_time_format_to_js_time_format(Settings["format.date"]) %>',
//   step: 15
// }).attr("autocomplete", "off");

/*
Expected behavior based on the code
  When:
    start date is a previous date, an alert message will pop up and the inputs will turn red
    start date is selected, the start time will be set to a future time from the current time and the end time will be the start time + 30 min ahead
    start date changes, the end date will be set to the same date
    start time changes, the end time will be set to the start time + 30 min ahead
    end date changes, only the end date will change
    end time changes, only the end time will change
    new start date is selected, the start time and end time will not change
*/
export function initialize_date_time_picker_dynamics() {
  // Example of DOM:
  // <div class="start-end-container">
  //  <div class="start"><duet-date-picker-container></div>
  //  <div class="end"><duet-date-picker-container></div>
  // </div>

  function corresponding_end_time_field(elem) {
    return $(elem).closest('.slot').find('[name$="[end(5i)]"]');
  }

  function corresponding_start_time_field(elem) {
    return $(elem).closest('.slot').find('[name$="[start(5i)]"]');
  }

  function corresponding_start_date_field(elem) {
    return $(elem).closest('.slot').find('.start .duet-date__input');
  }

  function corresponding_end_date_field(elem) {
    return $(elem).closest('.slot').find('.end .duet-date__input');
  }

  function round_minutes(date) {
    date.setHours(date.getHours() + Math.ceil(date.getMinutes()/60));
    date.setMinutes(0, 0, 0); // Resets also seconds and milliseconds
    return date;
  }

  function check_future_date(elem, event) {
    var container_selector = '.slot'; // This is a few levels up from .start-end-container; we need it to display an error message
    var $container_selector = $(container_selector)

    var selected_date = moment($(elem).val(), 'YYYY-MM-DD');
    var selected_date_hours = selected_date["_d"].setHours(0,0,0,0);

    var current_date = new Date();
    var current_date_hours = current_date.setHours(0,0,0,0);

    if ($(container_selector).length > 0) {
      elem.closest(container_selector).find('.message').remove();

      if (selected_date_hours < current_date_hours) {
        elem.closest(container_selector).append('<p class="message error">Please choose a future date</div>')
      } else {
        elem.closest(container_selector).find('.message').remove();
      }

      let boolean_check = selected_date_hours < current_date_hours && selected_date_hours != current_date_hours;
      $(elem).find('.duet-date__input').toggleClass('past-date-time-checker', boolean_check);
      $(elem).closest('.controls duet-date-picker').next().toggleClass('past-date-time-checker', boolean_check);
      $container_selector.find('.end .duet-date__input').toggleClass('past-date-time-checker', boolean_check);
      $container_selector.find('.end duet-date-picker').next().toggleClass('past-date-time-checker', boolean_check);
    }
  }

  // Move the end time to 30 minutes after the start time when the start time changes
  function handleStartTimeChange(elem) {
    var start_time = moment(corresponding_start_time_field(elem).val(), 'HH:mm:ss');

    if (start_time.isValid()) {
      var end_time = start_time.add(30, 'm');
      corresponding_end_time_field(elem).val(end_time.format('HH:mm:ss'));
    }
  }

  $('body').on('change', '[name$="[start(5i)]"]', function() {
    handleStartTimeChange(this);
  });

  // When the start date changes, set the end date to the start date
  // If the start time is empty, set it to the next hour
  // Then set the end time to 30 minutes after that
  // If the form has a data-future-only attribute, then do a past date check
  function handleStartDateChange(elem) {
    var start_date = moment($(elem).val(), 'YYYY-MM-DD');
    $(elem).closest('.controls').find('select').removeClass('past-date-time-checker');

    if (start_date.isValid()) {
      var end_date = start_date;

      corresponding_end_date_field(elem).closest('duet-date-picker').attr('value', end_date.format('YYYY-MM-DD'));
      corresponding_end_date_field(elem).next().val(end_date.format('YYYY-MM-DD'));

      var current_time_val = $(elem).closest('.controls').find('[name$="[start(5i)]"]').val();

      if (current_time_val.length == 0) {
        var current_date = new Date();
        var next_hour = round_minutes(current_date);

        var start_time = moment(next_hour, 'HH:mm:ss');
        $(elem).closest('.controls').find('[name$="[start(5i)]"]').val(start_time.format('HH:mm:ss'));
        var end_time = start_time.add(30, 'm');
        corresponding_end_time_field(elem).val(end_time.format('HH:mm:ss'));
      }
    }
  }

  $('body').on('duetChange', '.start duet-date-picker.hydrated', function (e) {
    handleStartDateChange(this);
    if ($(this).closest('form').data('future-only'))
      check_future_date($(this), e);
  });

  $('body').on('change', '.start .duet-date__input', function (e) {
    handleStartDateChange(this);
    if ($(this).closest('form').data('future-only'))
      check_future_date($(this), e);
  });

  // Move the end date to the start date if end date is less than start date
  function handleEndDateChange(elem) {
    var end_date = moment($(elem).val(), 'YYYY-MM-DD');
    var start_date = moment(corresponding_start_date_field(elem).val(), 'YYYY-MM-DD');

    if (start_date.isValid() && end_date < start_date) {
      corresponding_start_date_field(elem).closest('duet-date-picker').attr('value', end_date.format('YYYY-MM-DD'));
      corresponding_start_date_field(elem).next().val(end_date.format('YYYY-MM-DD'));
    }
  }

  $('body').on('change', '.end .duet-date__input', function (e) {
    handleEndDateChange(this);
    if ($(this).closest('form').data('future-only'))
      check_future_date($(this), e);
  });

  $('body').on('duetChange', '.end duet-date-picker.hydrated', function (e) {
    handleEndDateChange(this);
    if ($(this).closest('form').data('future-only'))
      check_future_date($(this), e);
  });
}
