// Nov 2023: This file is currently only being used inside the Survey Aggragate Table
export function automerge_columns() {
  // data-merge-columns is being passed in inside the render '/listings/items_table'
  // since table and table2 both show up on the DOM with the same data-merge-columns="0,1", we can target the first one on the DOM to get the value
  var $table = $('table.items-container.items-table');
  var cols = $table.first().data('merge-columns');

  // Only run if data-merge-columns exists
  if (cols != undefined) {
    $.each(cols, function(i, col) {
      var i_row = 0;
      var prev_td = null;
  
      $table.each(function () {
      // Going down the rows
        $("tbody", this).find('tr').each(function () {
          var cur_td = $(this).find('td:nth-child(' + col + 1 + ')');
  
          if (prev_td == null) {
            prev_td = cur_td;
            i_row = 0;
          } else if (cur_td.text() == prev_td.text()) {
            cur_td.remove();
            prev_td.attr('rowspan', i_row + 2);
            i_row++;
          } else {
            prev_td = cur_td;
            i_row = 0;
          }
        });
      });
    });
  }
}