// I'm not actually sure when these are called
export function initialize_remote_errors() {
  $('form').on('ajax:error', function(e, xhr, status, error) {
    var errors = $.parseJSON(xhr.responseText);

    display_remote_form_errors_on_top(errors);
    // display_remote_form_errors_on_fields(errors);
  });
}

// Converts the errors at the top of a form into contextual errors
// that attach next to the affected field.
// This is a more general form of /views/validate.js.erb,
// which is used exclusively for admin remote forms that
// have well-defined models.
export function initialize_errors() {
  if ($("#errors-on-page").length > 0) {
    var errors = $.parseJSON($("#errors-on-page").html());

    $('.form-row').removeClass('error').find('.message').removeClass('error').html('');

    $.each(errors, function(key, value) {
      let selector_string = '[name="' + key + '"]';
      let selector_string_of_array = '[name="' + key + '[]"]';
      let $input = $(selector_string);

      if ($input.length == 0) $input = $(selector_string_of_array);
      let input_id = $input.attr('id');

      $input.attr('aria-invalid', 'true');
      // Adding errors on multi-select
      $input
        .closest('.form-row')
        .addClass('error')
        .find('.message')
        .addClass('error')
        .attr('id', input_id + '_alert')
        .html( value.join(' & ') );

      $input.attr('aria-describedby', input_id + '_alert');

      // Adding errors on n-groups
      if ($input.length == 0) {
        // Adding errors to bottom of the empty n-group
        $input = $("#n-group-" + key);
        $input.addClass('error').find('.message').addClass('error').html( value.join(' & ') );
      };
    });

    if ($('#form-tabs .form-tab').length > 0) {
      var tab_panel_name = $('.form-row.error').first().closest('.ui-tabs-panel').attr('id');
      $('a[href="#' + tab_panel_name + '"]').click();
    }

    // Adds focus to the error notification at the top so the voice over can read it
    // TODO: I thought with the appropriate aria attributes, this should be unnecessary
    $('#main-alerts').attr('tabindex', '0');
    $('#main-alerts').focus();
  }
}

function display_remote_form_errors_on_top(errors) {
  // Errors will come in a hash of pairs in which the key is the field name or id (verify),
  // and the value is the text of the error message.
  //
  // The #form-errors-container is contained in /admin/_form_error.html.erb
  // Usually, it's just HTML (say, after a save that refreshes/redirects)
  // but sometimes a JSON response is returned.
  $.each(errors, function(key, value) {
    $("#form-errors-container").html(key + ": " + value);
    $("#form-errors-container").addClass("message");
    $("#form-errors-container").addClass("error");
  });
}

// I don't think this is being used, but in case it is, the model can't be passed in anymore
function display_remote_form_errors_on_fields(errors) {
  // Clear errors in the form
  $('.form-row').removeClass('error').find('.message').removeClass('error').html('');

  $.each(errors, function(key, value) {
    model = '<%= model.to_s %>';
    selector_string = '[name="' + model + '[' + key + ']"]';
    selector_string_of_id = '[name="' + model + '[' + key + '_id]"]';
    selector_string_of_array = '[name="' + model + '[' + key + '][]"]';
    $input = $(selector_string);

    if ($input.length == 0) $input = $(selector_string_of_array);
    if ($input.length == 0) $input = $(selector_string_of_id);
    if ($input.length == 0) {
      selector_string = '[name="' + key + '"]';
      selector_string_of_array = '[name="' + key + '[]"]';
      $input = $(selector_string);
      if ($input.length == 0) $input = $(selector_string_of_array);
    }

    $input.closest('.form-row').addClass('error').find('.message').addClass('error').html( value.join(' & ') );
  });
}
