export function initialize_users_selectize(selector = '[data-users-selectize]') {
  $(selector).each(function() {
    var max_items = $(this).data('max-items') || null
    var delimiter = $(this).data('delimiter') || ','
    var allow_create = $(this).data('allow-create') || false
    var group_ids = $(this).data('group-ids') || null
    var program_ids = $(this).data('program-id') || null
    var session_ids = $(this).data('session-ids') || null
    var users_url = $(this).data('users-url') || '/admin/users/accounts/users.json'

    $(this).selectize({
      preload: true,
      hideSelected: true,
      valueField: 'id',
      labelField: 'name',
      searchField: ['name', 'email', 'id'],
      options: [],
      maxItems: max_items,
      delimiter: delimiter,
      create: allow_create,
      render: {
        option: function(item, escape) {
          return '<div>' +
            '<span class="title">' +
                '<span class="name">' + escape(item.name) + '</span>' +
                '<span class="by">' + ' (' + escape(item.id) + ')</span>' +
            '</span>' +
            '<span class="description">' +
            escape(item.email) +
            '</span>' +
            '</div>';
        }
      },
      load: function(query, callback) {
        $.ajax({
          url: users_url,
          type: 'GET',
          dataType: 'json',
          data: {
            q: query,
            g: group_ids,
            p: program_ids,
            s: session_ids
          },
          error: function() {
            callback();
          },
          success: function(data) {
            callback(data);
          }
        });
      }
    });
  });
}

export function reload_users_selectize(selector) {
  $(selector).selectize()[0].selectize.destroy();
  initialize_users_selectize(selector);
}
