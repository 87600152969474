export function privacy_switch() {
  function checked_styling(el) {
    el.parent().css('background-color', '#3b82f6');
    el.parent().find('.slider-circle').removeClass('translate-x-0').addClass('translate-x-5');
  }

  function unchecked_styling(el) {
    el.parent().css('background-color', '#e5e7eb');
    el.parent().find('.slider-circle').removeClass('translate-x-5').addClass('translate-x-0');
  }

  update_privacy_group_switch();
  update_privacy_master_switch();

  $('.switch-user-privacy input').change(function() {
    $(this).is(':checked') ? checked_styling($(this)) : unchecked_styling($(this))
  });

  $('.user-privacy-master-switch input').click(function(e) {
    $('.switch-user-privacy input').prop("checked", $(this).prop("checked"));
    update_privacy_master_switch();
  });

  $('.user-privacy-group-switch input').click(function(e) {
    var group_name = $(this).data('target-name');
    $('#n-group-' + group_name + ' .switch-user-privacy input').prop("checked", $(this).prop("checked"));
    update_privacy_group_switch();
  });

  function update_privacy_group_switch() {
    $.each($('div.n-group'), function() {
      const group_name = $(this).find('.user-privacy-group-switch input').data("target-name");
      const group_switch = $('#n-group-' + group_name + ' .user-privacy-group-switch input');
      const visible_subfields_unchecked_switches = $('#n-group-' + group_name + ' .switch-user-privacy:not(.user-privacy-group-switch) input[type="checkbox"]:not(:checked)');
      const visible_subfields_checked_switches = $('#n-group-' + group_name + ' .switch-user-privacy:not(.user-privacy-group-switch) input[type="checkbox"]:checked');

      if (visible_subfields_unchecked_switches.length == 0) {
        group_switch.prop("checked", true);
        checked_styling(group_switch);
        checked_styling(visible_subfields_checked_switches);
      } else if (visible_subfields_checked_switches.length == 0) {
        group_switch.prop("checked", false);
        unchecked_styling(group_switch);
        unchecked_styling(visible_subfields_unchecked_switches);
      }
    });
  };

  function update_privacy_master_switch() {
    const master_switch = $('.user-privacy-master-switch input');
    const visible_private_unchecked_switches = $('.switch-user-privacy input[type="checkbox"]:not(:checked)');
    const visible_private_checked_switches = $('.switch-user-privacy input[type="checkbox"]:checked');

    if (visible_private_unchecked_switches.length == 0) {
      master_switch.prop("checked", true);
      checked_styling(master_switch);
      checked_styling(visible_private_checked_switches);
    } else if (visible_private_checked_switches.length == 0) {
      master_switch.prop("checked", false);
      unchecked_styling(master_switch);
      unchecked_styling(visible_private_unchecked_switches);
    }
  }
}

export function privacy_master_switch() {
  function checked_styling(el) {
    el.parent().css('background-color', '#3b82f6');
    el.parent().find('.slider-circle').removeClass('translate-x-0').addClass('translate-x-5');
  }

  function unchecked_styling(el) {
    el.parent().css('background-color', '#e5e7eb');
    el.parent().find('.slider-circle').removeClass('translate-x-5').addClass('translate-x-0');
  }

  update_privacy_group_switch();
  update_privacy_master_switch();

  $('.switch-user-privacy input').change(function() {
    $(this).is(':checked') ? checked_styling($(this)) : unchecked_styling($(this))
  });

  $('.user-privacy-master-switch input').click(function(e) {
    $('.switch-user-privacy input').prop("checked", $(this).prop("checked"));
    update_privacy_master_switch();
  });

  $('.user-privacy-group-switch input').click(function(e) {
    var group_name = $(this).data('target-name');
    $('#n-group-' + group_name + ' .switch-user-privacy input').prop("checked", $(this).prop("checked"));
    update_privacy_group_switch();
  });

  function update_privacy_group_switch() {
    $.each($('div.n-group'), function() {
      const group_name = $(this).find('.user-privacy-group-switch input').data("target-name");
      const group_switch = $('#n-group-' + group_name + ' .user-privacy-group-switch input');
      const visible_subfields_unchecked_switches = $('#n-group-' + group_name + ' .switch-user-privacy:not(.user-privacy-group-switch) input[type="checkbox"]:not(:checked)');
      const visible_subfields_checked_switches = $('#n-group-' + group_name + ' .switch-user-privacy:not(.user-privacy-group-switch) input[type="checkbox"]:checked');

      if (visible_subfields_unchecked_switches.length == 0) {
        group_switch.prop("checked", true);
        checked_styling(group_switch);
        checked_styling(visible_subfields_checked_switches);
      } else if (visible_subfields_checked_switches.length == 0) {
        group_switch.prop("checked", false);
        unchecked_styling(group_switch);
        unchecked_styling(visible_subfields_unchecked_switches);
      }
    });
  };

  function update_privacy_master_switch() {
    const master_switch = $('.user-privacy-master-switch input');
    const visible_private_unchecked_switches = $('.switch-user-privacy input[type="checkbox"]:not(:checked)');
    const visible_private_checked_switches = $('.switch-user-privacy input[type="checkbox"]:checked');

    if (visible_private_unchecked_switches.length == 0) {
      master_switch.prop("checked", true);
      checked_styling(master_switch);
      checked_styling(visible_private_checked_switches);
    } else if (visible_private_checked_switches.length == 0) {
      master_switch.prop("checked", false);
      unchecked_styling(master_switch);
      unchecked_styling(visible_private_unchecked_switches);
    }
  }
}
