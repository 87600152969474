export function jump_link() {
  $('.jump-link').click(function(e) {
    var target_id = $(this).data('field-id');
    var $first_label;

    if ($('#' + target_id).length > 0) {
      // Most non-field groups will have a matching ID
      $first_label = $('#' + target_id).closest('.form-row, .field-group-entry').find('label');
    } else {
      var counter = $('#' + target_id + '_counter_');
      if (counter.length > 0) {
        // If field group
        $first_label = counter.closest('.form-row, .field-group-entry').find('label');
      } else {
        // Look for an array-type element
        $first_label = $('input[name="' + target_id + '[]"][type!="hidden"]').first().siblings('label');

        if ($first_label.length == 0) {
          $first_label = $("#n-group-" + target_id).find('label');
        }

        if ($first_label.length == 0) {
          // Radio buttons will have the name of target_id but the ids themselves will be suffixed
          // But this will be more of a catchall for any type of field that behaves this way
          $first_label = $('input[name="' + target_id + '"][type!="hidden"]').first().siblings('label');
        }
      }
    }

    if ($first_label.is(":hidden")) {
      var parent_tab_panel_id = $first_label.closest('[role="tabpanel"]').attr('id');
      var $parent_tab_link = $('#' + parent_tab_panel_id + '-label');

      $parent_tab_link.click();
    }

    var elem_id = $first_label.attr('for');
    if ($('#' + elem_id).length > 0) {
      $('#' + elem_id).focus();
    } else {
      elem_id = $first_label.attr('for') + '-selectized';
      $('#' + elem_id).focus();
    }

    e.preventDefault();
  });


  // 6/9/23: Removed this element from the DOM for now. Not really necessary since we have the jump-link.
  $('.return-link').click(function(e) {
    $(".alerts").get(0).scrollIntoView({behavior: 'smooth'});

    e.preventDefault();
  });
}