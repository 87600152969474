export default class TruncateElement {
  // static = call a method as a class method (without creating an object instance)
  static initialize() {
    // There are two types of truncates
    // data_max_pixels: will have a set height and the bottom texts will be gradient
    // data_max_children: will show one or more row of data and hide the rest

    var $more_link = '<a href="#" class="read-more toggle-link" aria-expanded="false">More</a>';
    var $less_link = '<a href="#" class="read-less toggle-link" aria-expanded="true">Less</a>';

    // On load we will collapse all the truncate container's on the page
    truncate($('.truncate-container'));

    // Wait for the calendar to load first and then truncate
    var check_calendar_existence = setInterval(function() {
      if ($('#full-calendar').length) {
        truncate($('.truncate-container'));
        clearInterval(check_calendar_existence);
      }
    }, 80);

    $(document).on('click', '.toggle-link', function(e) {
      e.preventDefault();
      var $this = $(this);
      var $truncate_container = $this.parent();
      var expanded = $this.attr('aria-expanded') == 'false';

      if ($this.parent().data('max-pixels')) {
        expanded ? expand($truncate_container) : collapse_gradient($truncate_container);
      } else {
        expanded ? expand($truncate_container) : collapse_row($truncate_container);
      }
    });

    function truncate(container) {
      container.each(function() {
        var $this = $(this);
        $this.data('max-pixels') ? collapse_gradient($this) : collapse_row($this);
      });
    }

    function collapse_gradient(truncate_container) {
      var max_pixels = truncate_container.data('max-pixels') || '150px';
      var $visible_data = truncate_container.find('#visible-data');
      var $children = truncate_container.children().not('.toggle-link, a.left');

      truncate_container.find('.toggle-link').remove();

      $visible_data.css('height', truncate_container.data('max-pixels'));
      $visible_data.removeClass('view-all');

      // Add the more link to the data content if #visible-data is on the DOM
      if ($visible_data.length > 0) {
        truncate_container.append($more_link);
        $visible_data.append($children);
        $visible_data.css('height', max_pixels);
      }
      
      // Add the visible data div onto the DOM if there are any data
      if ($visible_data.children().length > 0) {
        $(truncate_container).prepend("<div id=\"visible-data\"></div>");
        truncate_container.append($more_link);
        truncate_container.find('#visible-data').append($children);
        truncate_container.find('#visible-data').css('height', max_pixels);
      }
    }

    function collapse_row(truncate_container) {
      var $children = truncate_container.children().not('.toggle-link, a.left');
      var max_children = truncate_container.data('max-children') || 1;

      truncate_container.find('.toggle-link').remove();

      if ($children.length > max_children) {
        $children.hide();
        truncate_container.append($more_link);
        $children.slice(0, parseInt(max_children)).show();
      }
    }

    function expand(truncate_container) {
      if (truncate_container.data('max-pixels')) {
        var $visible_data = truncate_container.find('#visible-data');

        $visible_data.css('height', '100%');
        $visible_data.css('background', 'transparent');
        $visible_data.addClass('view-all');
      }

      truncate_container.find('.toggle-link').remove();
      truncate_container.children().not('.toggle-link, a.left').show();
      truncate_container.append($less_link);
    }
  }
}