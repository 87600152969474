export function toggle_switch_focus() {
  // 11-27-2023: Since tailwind is our main css library, it uses var(--tw-ring-color) / #3b82f680 for .button:focus, [type=submit]:focus
  // Most likely our clients will have custom css for their focus button, but if they don't it will use this default color
  const TAILWIND_DEFAULT_RING_COLOR = '#3b82f680';

  $(document).on('focus', '.switch-container input', function(e) {
    $(e.currentTarget).closest('.switch-container').css('border', '2px solid ' + TAILWIND_DEFAULT_RING_COLOR);
  })

  $(document).on('blur', '.switch-container input', function(e) {
    $(e.currentTarget).closest('.switch-container').css('border', '2px solid transparent');
  });
}