export function toggle_help() {
  $('body').on('click', 'a.toggle-field-help', function(e) {
    // Move the help-inline to the label element with js, only for: admin/settings/user_interface#tab-ui
    // $(this).closest('.form-row').find('.controls .help-inline').appendTo($(this).closest('.form-row').find('label'));

    $(this).closest('.form-row, td').find('.help-inline').toggleClass('hidden');
    e.preventDefault();
  });

  $('body').on('click', 'a.toggle-help', function(e) {
    // Move the help-inline to the label element with js, only for: admin/settings/user_interface#tab-ui
    // $(this).closest('.form-row').find('.controls .help-inline').appendTo($(this).closest('.form-row').find('label'));

    $(this).closest('div').find('.help-content').toggleClass('hidden');
    e.preventDefault();
  });
}