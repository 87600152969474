// add a click handler to all internal links
export function internal_links_handler() {
  $("a[href^='#']").on('click', function() {
    if ($(this).attr("href") == '#main-content') {
      $('#main-content').attr('tabindex', '-1');
      $("#"+$(this).attr("href").slice(1)+"").focus();
      $('#main-content').removeAttr('tabindex');
    }
  });
}
