import { rerank_table } from "./sort";
import FoundationMethods from './foundation_methods';

export function auto_reinitialization() {
  // This is for Rails-UJS requests, which includes
  // - admin updating rows
  //
  // Note that deletions and replacements (updates) of HTML
  // that the triggering element is a part of won't trigger this
  // because the triggering element will be detached from the
  // broader document by the time this event occurs, so the
  // event never bubbles up to document.
  document.addEventListener("ajax:success", (event) => {
    // We can't actually distinguish the HTTP verb here
    common_initializations();
    common_resets();
  })

  document.addEventListener("ajax:error", (event) => {
    // We can't actually distinguish the HTTP verb here
    common_initializations();
  })

  // This is for:
  // - best-in-place responses
  // - modal opens
  // - admin filters
  $(document).ajaxSuccess(function(event, xhr, settings) {
    common_initializations();
    // we have an ajax with a type POST inside the pending_connections_controller.js
    // we don't want the modal to close right after this ajax success
    // we might encounter some other cases where we don't want the modal to close right away..
    let prevent_modal_close = '/my/connections/pending';

    if (settings.type == 'GET') {
      common_initializations();
    } else if (settings.type == 'POST' && settings.url != prevent_modal_close) {
      common_resets();
    }
  });

  $(document).ajaxError(function(event, xhr, settings) {
  });

  // Hopefully we can leverage turbo stream
  $(document).on('turbo:submit-end', function(e) {
    if (e.detail.success) {
      common_initializations();
      // common_resets();
    } else {

    }
  });
}

export function common_initializations() {
  FoundationMethods.initialize();
  initialize_selectize();
  $(".best_in_place").best_in_place();
  rerank_table();
}

export function common_resets() {
  $('#modal-box').foundation('close');
  $('#modal-box-2').foundation('close');
  $('#popup').foundation('close');
}