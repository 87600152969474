export default class TruncateText {
  // static = call a method as a class method (without creating an object instance)
  static initialize() {
    if($('.email-body').length > 0 || $('.prompt').length > 0) {
      $('.email-body, .prompt').truncate({
        maxLines: 2,
        truncateString: '...',
        showText: 'More',
        hideText: 'Less',
        showClass: 'read-more',
        hideClass: 'read-less'
      });
    }
  }
}