import { initialize_selectize } from "./initialize_selectize";
import { initialize_users_selectize } from "./initialize_users_selectize";
//import { computePosition } from "@floating-ui/dom";

// #popup is similar to a #modal-box so added the code here
// when the content inside a modal gets updated the close button isn't present
// since it appends the close button when a modal is opened
export function open_modal() {
  // for the make_header in application_helper.rb we will use js to append the close button and the others will have the close button inside the header components
  let close_button = '<div class="close-button-container">\
      <button class="text-md !text-black relative !bg-transparent hover:!bg-transparent w-10 h-10" aria-label="Close dialog box" type="button" data-close>\
        <span class="hover:bg-slate-200 w-full h-full rounded-full flex justify-center align-middle">\
          <i class="fal fa-times text-2xl"></i>\
        </span>\
      </button>\
    </div>';

  $('body').on('click', '[data-reveal-ajax="true"]', function(e) {
    var modal_id = $(this).data('open-in');
    var $clicked_element;
    var url = $(this).attr('href');
    
    // prevents the popup from shifting positions if a click outside of .link-to-popup is clicked
    if ($(e.target).hasClass('link-to-popup')) {
      $clicked_element = $(this);
    }

    $('#' + modal_id).html('<div class="muted center padded-3em note large"><i class="fa fa-spinner fa-pulse"></i></div>');

    e.preventDefault();

    $.ajax(url).done(function (resp) {
      var $popup = $('#popup');

      $('#' + modal_id).html(resp).foundation('open');

      resize_modal();
      initialize_selectize();
      initialize_users_selectize();
      privacy_switch();
      privacy_master_switch();
      append_close_button_to_header(modal_id, close_button);

      // make sure this is higher than the #modal-box and #popup since it means
      // it opened up from inside those elements and should be the first layer
      if ($('#modal-box-2').length > 0) {
        $('#modal-box-2').parent().css('z-index', '1010');
      }

      // this means the .link-to-popup was clicked
      if($clicked_element != undefined) {
        popup($popup, $clicked_element);
      }
    });
  });

  $("#modal-box, #modal-box-2").draggable({
    handle: '.header-wrapper'
  });
}

function append_close_button_to_header(modal_id, close_button) {
  if (modal_id == 'modal-box') {
    if ($('#modal-box header .close-button-container').length <= 0) {
      $('#modal-box header').append(close_button);
    }
  }

  if (modal_id ==  'modal-box-2') {
    if ($('#modal-box-2 header .close-button-container').length <= 0) {
      $('#modal-box-2 header').append(close_button);
    }
  }

  if(modal_id == 'popup') {
    if ($('#popup header .close-button-container').length <= 0) {
      $('#popup header').append(close_button);
    }
  }
}

function popup($popup, $clicked_element) {
  if ($popup.length > 0) {
    var clicked_offset = $clicked_element.offset();
    var clicked_left = clicked_offset.left;
    var clicked_right = clicked_offset.left + $clicked_element.outerWidth();

    var available_space_left = clicked_left;
    var available_space_right = $(window).width() - clicked_right
    var popup_position = available_space_left > available_space_right ? 'left' : 'right';
    var popupWidth = $popup.outerWidth();
    var popupOffset = 18;

    if (popup_position === 'left') {
      var left = clicked_left - (popupWidth / 2) - popupOffset;
      var top = clicked_offset.top + popupOffset;

      $popup.css('left', left + 'px');
      $popup.prop('style').setProperty('left', left + 'px', 'important');
      $popup.css('top', top + 'px');
      $popup.prop('style').setProperty('top', top + 'px', 'important');
    } else {
      var right = clicked_right + (popupWidth / 2) + popupOffset;
      var top = clicked_offset.top + popupOffset;

      $popup.css('left', right + 'px');
      $popup.prop('style').setProperty('left', right + 'px', 'important');
      $popup.css('top', top + 'px');
      $popup.prop('style').setProperty('top', top + 'px', 'important');
    }
  }
}

/* Feb 2022: For now we will use this code to change the modal size.
Add this tag inside the file that needs to resize: <div id="modal-box-settings" data-size=""></div> */
export function resize_modal() {
  adjust_classes($('#modal-box'));
  adjust_classes($('#modal-box-2'));

  $(window).on('closed.zf.reveal', '#modal-box', function() {
    $('#modal-box').attr('class', 'reveal medium');
    $('#modal-box').html('');
  });

  $(window).on('closed.zf.reveal', '#modal-box-2', function() {
    $('#modal-box-2').attr('class', 'reveal medium');
    $('#modal-box-2').html('');
  });
}

function adjust_classes(modal) {
  modal.css({ 'padding': '1.5rem', 'overflow-y': 'auto' });

  if (modal.children('#modal-box-settings').length > 0) {
    const modal_size = modal.find('#modal-box-settings').data('size');
    const modal_no_padding = modal.find('#modal-box-settings').data('no-padding');

    modal.attr('class', 'reveal ' + modal_size);

    // No padding implies that the body of the modal should provide its own scrollbars,
    // which means the modal itself should have overflow hidden
    if (modal_no_padding !== undefined) {
      modal.css({ 'padding': '0', 'overflow-y': 'visible' });
    }
  }
}