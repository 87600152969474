export function initialize_sortables() {
  $("#form-tabs.sortable").sortable({
    axis: "x",
    items: 'li',
    distance: 5,
    stop: function() {
      // $tabs.tabs( "refresh" );
    },
    update: function(e, ui) {
      update_function(e, ui, '.tabs');
    }
  });

  $('.row.sortable').sortable({
    axis: 'y',
    items: '.field-row',
    distance: 5,
    handle: '.drag-handle',
    update: function(e, ui) {
      update_function(e, ui, '.row');
    }
  });

  $('.grid-x.sortable').sortable({
    axis: 'y',
    items: '.field-row',
    distance: 5,
    handle: '.drag-handle',
    update: function(e, ui) {
      update_function(e, ui, '.grid-x');
    }
  });

  // For the admin survey to move the entire field
  $('.tab-content.sortable').sortable({
    axis: 'y',
    items: '.sfield-panel-container',
    distance: 5,
    handle: '.drag-handle',
    update: function(e, ui) {
      update_function(e, ui, '.sfield-panel-container');
    }
  });

  // For the admin survey to move the field options
  $('.tab-content.sortable .survey-content').sortable({
    axis: 'y',
    items: '.field-row',
    distance: 5,
    handle: '.drag-handle',
    update: function(e, ui) {
      update_function(e, ui, '.field-row');
    }
  });

  $('table.sortable').sortable({
    axis: 'y',
    items: 'tr',
    distance: 5,
    handle: '.drag-handle',
    update: function(e, ui) {
      update_function(e, ui, 'table');
    }
  });

  $('.tabs-panel.sortable, #no-tab.sortable').sortable({
    // axis: 'y',
    items: '> .tab-content > div.unit-container',
    handle: '.action.drag-handle',
    distance: 5,
    helper: function(e, div_row) {
      var $originals = div_row.children(); // a collection of div columns
      var $helper = div_row.clone(); // also a collection of div columns
      $helper.children().each(function(index) {
        // Set td sizes to match the original td sizes
        $(this).width($originals.eq(index).width());
      });
      return $helper;
    },
    stop: function(e, ui) {
      // highlight the row on drop to indicate an update
      ui.item.children('td').effect('highlight', {color: '#eee'}, 1000);
    },
    update: function(e, ui) {
      update_function(e, ui, '.sortable');
    }
  });

  // For move-* functions
  $('table').on('click', '.move-up', function () {
    var clicked_tr = $(this).closest('tr');
    clicked_tr.insertBefore(clicked_tr.prev());
  });

  $('table').on('click', '.move-down', function () {
    var clicked_tr = $(this).closest('tr');
    clicked_tr.insertAfter(clicked_tr.next());
  });
}
