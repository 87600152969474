export default class FoundationMethods {
  static initialize() {
    initializeFoundation();

    Foundation.Reveal.defaults.multipleOpened = true;

    Foundation.Keyboard.register('DropdownMenu', {
      'SPACE': 'open',
      'ARROW_RIGHT': 'next',
      'ARROW_UP': 'up',
      'ARROW_DOWN': 'down',
      'ARROW_LEFT': 'previous',
      'ESCAPE': 'close'
    });

    $("[data-accordion]").on("down.zf.accordion", function() {
      Foundation.reInit('equalizer');
    });
  }
}


// 11/29/2023 NOTE:
// Added these for the show_tooltip_on_hover.js file
// As of now, the elements inside admin table shows tooltip on page load and focus when we have these code

// Flag to check if Foundation is already initialized
var foundationInitialized = false;

// Function to initialize Foundation
// Scripts may execute before the DOM is fully ready. To ensure it runs after, wrap it around a setTimeout
function initializeFoundation() {
  if (!foundationInitialized) {
    setTimeout(function() {
      $(document).foundation();
    }, 1000);

    foundationInitialized = true;
  } else { // we will destroy existing Foundation and reinitialize here
    var elementsToReinitialize = $(document);
    destroyFoundationOnElements(elementsToReinitialize);

    setTimeout(function() {
      $(document).foundation();
    }, 500);
  }
}

// Destroy existing Foundation instances
function destroyFoundationOnElements(elements) {
  elements.each(function () {
    var $element = $(this);

    // Check if the element has a Foundation instance before attempting to destroy
    if ($element.data('zfPlugin')) {
      // Get the Foundation instance and destroy it
      var instance = $element.data('zfPlugin');
      instance.destroy();
    }
  });
}


