// For the compact calendar: keep the tooltip open when date is clicked so users can click on the event link
export function calendar_tooltip_handler() {
  $(document).on('click', function(e) {
    if ($(e.target).closest(".details").length === 0) {
      $('.event-date').blur();
    } else {
      if ($(e.target).hasClass('event-date')) {
        $(e.target).trigger('focus');
        $(e.target).focus();

        // Add tw styling
        $(e.target).closest('body').find('#' + $(e.target).attr('aria-describedby')).addClass('shadow-xl border');
      }
    }
  });
}