// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// Example: Load Rails libraries in Vite.
//
import { Turbo } from '@hotwired/turbo-rails'
Turbo.session.drive = false
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

// ----------------------------------------------------------------------------------------------------
import Rails from "@rails/ujs";
window.Rails = Rails;

import "../regex_selector/jquery.regex-selector";

import Cookies from 'js-cookie'
window.Cookies = Cookies

import Chart from "chart.js";
window.Chart = Chart;

import "jquery-autosize";
// import "jquery-datetimepicker";
// import "jquery-datetimepicker/jquery.datetimepicker.css";

import "jquery-serializejson";
// import "jquery_metadata";

import hopscotch from "hopscotch";
window.hopscotch = hopscotch;
import "hopscotch/dist/css/hopscotch.css";

import "slick-carousel";
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";

import "../xinspire/calendar";
import "../xinspire/thumbnails";

// import "../xinspire/initialize_dependent_fields";
import "../xinspire/remove_ngroup_template";
import "../xinspire/privacy_switch";

// NOTES: we have datatables cdn inside the common_scripts so we might not need all these
// Unfortunately, we can't put these in admin.js
// or else $.fn.dataTable will be undefined.
// Not sure why...
// import 'datatables.net';
// import 'datatables.net-dt';
// import 'datatables.net-dt/css/jquery.dataTables.css';
// import 'datatables.net-zf';
// import 'datatables.net-buttons';
// import 'datatables.net-buttons-zf';
// import 'datatables.net-plugins/sorting/datetime-moment';
// import 'jquery-datatables-checkboxes';
// import 'datatables.net-buttons/js/buttons.html5.js';

import { StarRating } from '../star-rating/dist/star-rating.js';
window.StarRating = StarRating;
import '../star-rating/dist/star-rating.css';

// import trumbowyg_icons from 'trumbowyg/dist/ui/icons.svg';
import 'trumbowyg';
import 'trumbowyg/dist/plugins/mention/trumbowyg.mention.js';
window.$.trumbowyg.svgPath = false;
window.$.trumbowyg.hideButtonTexts = true;

import { rerank_table, update_function, sort_elements } from "../xinspire/sort";
window.rerank_table = rerank_table;
window.update_function = update_function;
window.sort_elements = sort_elements;

import { update_index, update_index_post } from "../xinspire/filter";
window.update_index = update_index;
window.update_index_post = update_index_post;

import { resize_modal } from '../xinspire/modals';
// window.center_modal = center_modal;
window.resize_modal = resize_modal;

import { scroll_into_view_if_needed  } from "../xinspire/scroll_into_view_if_needed";
window.scroll_into_view_if_needed = scroll_into_view_if_needed;

import { automerge_columns } from "../xinspire/admin/automerge_columns";
window.automerge_columns = automerge_columns;

// import { toggle_dependent_fields, toggle_dependent_sfields, initialize_dependent_fields, initialize_dependent_sfields } from "../xinspire/dependent_fields";
// window.toggle_dependent_fields = toggle_dependent_fields;
// window.toggle_dependent_sfields = toggle_dependent_sfields;
// window.initialize_dependent_fields = initialize_dependent_fields;
// window.initialize_dependent_sfields = initialize_dependent_sfields;

import { initialize_date_time_picker_dynamics } from "../xinspire/date_time_picker";
window.initialize_date_time_picker_dynamics = initialize_date_time_picker_dynamics;

import { disable_enter_submission } from "../xinspire/disable_enter_submission";
window.disable_enter_submission = disable_enter_submission;

import { display_upload } from "../xinspire/display_upload";
window.display_upload = display_upload;

import { grid_draggable } from "../xinspire/grid_draggable";
window.grid_draggable = grid_draggable;

import { jump_link } from "../xinspire/jump_link";
window.jump_link = jump_link;

import { navigate_tabs } from "../xinspire/navigate_tabs";
window.navigate_tabs = navigate_tabs;

import { privacy_switch, privacy_master_switch } from "../xinspire/privacy_switch";
window.privacy_switch = privacy_switch;
window.privacy_master_switch = privacy_master_switch;

import { table_sortable } from "../xinspire/table_sortable";
window.table_sortable = table_sortable;

import { update_triggering_fields } from "../xinspire/update_triggering_fields";
window.update_triggering_fields = update_triggering_fields;

import { update_triggering_sfields } from "../xinspire/update_triggering_sfields";
window.update_triggering_sfields = update_triggering_sfields;

import { initialize_data_tables } from "../xinspire/admin/initialize_data_tables";
window.initialize_data_tables = initialize_data_tables;

import { initialize_color } from "../xinspire/admin/initialize_color";
window.initialize_color = initialize_color;

import { initialize_remote_errors, initialize_errors } from "../xinspire/errors";
window.initialize_remote_errors = initialize_remote_errors;
window.initialize_errors = initialize_errors;

import { initialize_sortables } from "../xinspire/initialize_sortables";
window.initialize_sortables = initialize_sortables;

import { initialize_tabs } from "../xinspire/initialize_tabs";
window.initialize_tabs = initialize_tabs;

import { sort_cards } from '../xinspire/sort_cards';
window.sort_cards = sort_cards;

import {
  initialize_box,
  initialize_other_box,
  change_on_form_select,
  select_all,
  deselect_all
} from '../xinspire/toggle_checked_input';
window.initialize_box = initialize_box;
window.initialize_other_box = initialize_other_box;
window.change_on_form_select = change_on_form_select;
window.select_all = select_all;
window.deselect_all = deselect_all;

import { connect, createLocalVideoTrack, createLocalAudioTrack } from "twilio-video";
window.twilio_connect = connect;
window.twilio_createLocalVideoTrack = createLocalVideoTrack;
window.twilio_createLocalAudioTrack = createLocalAudioTrack;

import { open_modal } from '../xinspire/modals';
window.open_modal = open_modal;

import { initialize_selectize } from "../xinspire/initialize_selectize";
window.initialize_selectize = initialize_selectize;

import { initialize_users_selectize, reload_users_selectize } from "../xinspire/initialize_users_selectize";
window.initialize_users_selectize = initialize_users_selectize;
window.reload_users_selectize = reload_users_selectize;

import { initialize_animations } from "../xinspire/initialize_animations";
window.initialize_animations = initialize_animations;

import { initialize_survey_matrix } from "../xinspire/initialize_survey_matrix";
window.initialize_survey_matrix = initialize_survey_matrix;

import TruncateText from '../xinspire/truncate_text';
window.TruncateText = TruncateText;

import ReinitializeTruncate from '../xinspire/reinitialize_truncate';
window.ReinitializeTruncate = ReinitializeTruncate;

import TruncateElement from '../xinspire/truncate_element';
window.TruncateElement = TruncateElement;

import TruncateElementMaster from '../xinspire/truncate_element_master';
window.TruncateElementMaster = TruncateElementMaster;

import FoundationMethods from '../xinspire/foundation_methods';
window.FoundationMethods = FoundationMethods;

import { page_state } from '../xinspire/page_state';
window.page_state = page_state;

import { internal_links_handler } from '../xinspire/internal_links_handler';
window.internal_links_handler = internal_links_handler;

import { more_options } from '../xinspire/more_options';
window.more_options = more_options;

// import { show_tooltip_on_hover } from '../xinspire/accessibility/show_tooltip_on_hover';
// window.show_tooltip_on_hover = show_tooltip_on_hover;

import { toggle_help } from '../xinspire/toggle_help';
window.toggle_help = toggle_help;

import { delete_row } from '../xinspire/delete_row';
window.delete_row = delete_row;

import { close_button } from '../xinspire/close_button';
window.close_button = close_button;

import { calendar_tooltip_handler } from '../xinspire/calendar_tooltip_handler';
window.calendar_tooltip_handler = calendar_tooltip_handler;

import { toggle_tags_handler } from '../xinspire/toggle_tags_handler';
window.toggle_tags_handler = toggle_tags_handler;

import { copy_to_clipboard } from '../xinspire/copy_to_clipboard';
window.copy_to_clipboard = copy_to_clipboard;

// import { attach_n_group_add } from '../xinspire/n_groups_add';
// window.attach_n_group_add = attach_n_group_add;

// import { attach_n_group_delete } from '../xinspire/n_groups_delete';
// window.attach_n_group_delete = attach_n_group_delete;

import { toggle_switch_focus } from '../xinspire/accessibility/toggle_switch_focus';
window.toggle_switch_focus = toggle_switch_focus;

import { focus_on_open_modal } from '../xinspire/accessibility/focus_on_open_modal';
window.focus_on_open_modal = focus_on_open_modal;

import { auto_reinitialization, common_initializations } from '../xinspire/auto_reinitialization';
window.auto_reinitialization = auto_reinitialization;
window.common_initializations = common_initializations;

import { replace_placeholder_token } from "../xinspire/placeholder_highlighting";
window.replace_placeholder_token = replace_placeholder_token;