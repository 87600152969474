export function navigate_tabs() {
  $(function() {
    $('.prev-tab').click(function(e) {
      var $target_tab_link = $(this).closest('.responsive-tabs-container').find('.is-active').prev('li').find('a[role="tab"]');
      $target_tab_link.click();
      e.preventDefault();
      $target_tab_link.focus();
    });

    $('.next-tab').click(function(e) {
      var $target_tab_link = $(this).closest('.responsive-tabs-container').find('.is-active').next('li').find('a[role="tab"]');
      $target_tab_link.click();
      e.preventDefault();
      $target_tab_link.focus();
    });
  });
}
