export function update_index(e) {
  var form = $('#form-filters');

  $.ajax({
    type: 'get',
    url: window.location.href.split('?')[0],
    dataType: 'script',
    data: form.serialize(),
    complete: function() {
      $('#filter-box').on('show.zf.dropdown', function() {
        $('#filter-box').css("left", $(".filters-bar-container").first().offset().left + "px");
        $('#filter-box').css("top", $(".filters-bar-container").first().offset().top + $(".filters-bar-container").first().height() + "px");
      });
    }
  });
}

export function update_index_post(e) {
  var form = $('#form-filters');
  $.ajax({
    type: 'post',
    url: $(this).data('url'),
    dataType: 'script',
    data: form.serialize(),
    complete: function() {
    }
  });
}
