// This toggles all the truncate cells inside the table to expand/collapse

export default class TruncateElementMaster {
    // static = call a method as a class method (without creating an object instance)
    static initialize() {
      if($('.items-table-wrapper').length > 0 && $('.truncate-container').length > 0) {
        // for tables that have pagination
        if ($('.pagination-container').length > 0) {
          $('.table-actions').prepend('<div class="toggle-master-link-container"><a class="expand-cards-master-link toggle-master-link">Expand All</a></div>');
          $('.pagination-container').css('display', 'flex');
          $('.toggle-master-link-container').css('margin-right', '1em');
  
          $(document).on('click', '.toggle-master-link', function(e) {
            e.preventDefault();
            var $view_all = $(this).hasClass('expand-cards-master-link');
            $('.toggle-master-link-container').remove();
  
            if ($view_all) {
              $('.expand-cards-master-link').remove();
              $('.table-actions').prepend('<div class="toggle-master-link-container"><a class="collapse-all-cards-master-link toggle-master-link">Collapse All</a></div>');
              $('.read-more').trigger('click');
            } else {
              $('.collapse-all-cards-master-link').remove();
              $('.table-actions').prepend('<div class="toggle-master-link-container"><a class="expand-cards-master-link toggle-master-link">Expand All</a></div>');
              $('.read-less').trigger('click');
            }
  
            $('.toggle-master-link-container').css('margin-right', '1em');
          });
  
        // for tables that have no pagination
        } else {
          $('.items-toolbar .actions.menu').append('<div class="toggle-master-link-container"><a class="expand-cards-master-link toggle-master-link">Expand All</a></div>');
          $('.actions.menu').css('width', '100%');
  
          $('.toggle-master-link-container').css({
            'display': 'flex',
            'margin-left': 'auto'
          });
  
          $(document).on('click', '.toggle-master-link', function(e) {
            e.preventDefault();
            var $view_all = $(this).hasClass('expand-cards-master-link');
            $('.toggle-master-link-container').remove();
  
            if ($view_all) {
              $('.expand-cards-master-link').remove();
              $('.items-toolbar .actions.menu').append('<div class="toggle-master-link-container"><a class="collapse-all-cards-master-link toggle-master-link">Collapse All</a></div>');
  
              $('.toggle-master-link-container').css({
                'display': 'flex',
                'margin-left': 'auto'
              });
  
              $('.read-more').trigger('click');
            } else {
              $('.collapse-all-cards-master-link').remove();
              $('.items-toolbar .actions.menu').append('<div class="toggle-master-link-container"><a class="expand-cards-master-link toggle-master-link">Expand All</a></div>');
  
              $('.toggle-master-link-container').css({
                'display': 'flex',
                'margin-left': 'auto'
              });
              $('.read-less').trigger('click');
            }
          });
        }
  
        // This removes the truncate master link on Unassigned Circle table
        $('.circle-table2').closest('form').find('.toggle-master-link-container').remove();
      }
    }
}