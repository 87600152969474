export function initialize_box() {
  $('input[type="checkbox"][value="-1"], input[type="radio"][value="-1"]').click(function(event) {
    $(this).next().next('.other-box').toggle($(this).prop('checked'));
  });
}

export function initialize_other_box() {
  $.each($('input[type="checkbox"][value="-1"], input[type="radio"][value="-1"]'), function() {
    $(this).next().next('.other-box').toggle($(this).prop('checked'));
  });
}

export function change_on_form_select() {
  $('body').on('change', 'form select', function(event) {
    var select_val = $(this).val();
    if (select_val == null) {
      // Nothing
    } else if (select_val.constructor === Array) {
      $(this).nextAll('.other-box').toggle(select_val.indexOf('-1') > -1);
    } else if (typeof select_val == 'string') {
      $(this).nextAll('.other-box').toggle(select_val == '-1');
    }
  });
}

// Select all UI for multi checklists
export function select_all() {
  $('.select-all').click(function(e) {
    $(this).closest('.controls').find('input[type="checkbox"]').prop('checked', true);
    e.preventDefault();
  });
}

export function deselect_all() {
  $('.deselect-all').click(function(e) {
    $(this).closest('.controls').find('input[type="checkbox"]').prop('checked', false);
    e.preventDefault();
  });
}