export function table_sortable() {
  $('.items-table.sortable').sortable({
    axis: 'y',
    items: 'tbody > tr',
    handle: '.drag-handle',
    helper: function(e, tr) {
      var $originals = tr.children(); // a collection of td's
      var $helper = tr.clone(); // also a collection of td's
      $helper.children().each(function(index) {
        // Set td sizes to match the original td sizes
        $(this).width($originals.eq(index).width());
      });
      return $helper;
    },
    stop: function(e, ui) {
      // highlight the row on drop to indicate an update
      ui.item.children('td').effect('highlight', {color: '#eee'}, 1000);
    },
    update: function(e, ui) {
      update_function(e, ui, 'table');
      rerank_table();
    }
  });
}