export function grid_draggable() {
  $('.items-grid.draggable .grid-cell').draggable({
    addClasses: false,
    revert: 'invalid',
    items: '.grid-cell',
    handle: '.drag-handle',
    opacity: 0.7
  });

  $('.items-grid.droppable .grid-cell').droppable({
    items: '.grid-cell',
    accept: '.items-grid.draggable .grid-cell',
    tolerance: 'pointer',
    hoverClass: 'ui-state-hover-receiver',
    drop: function(e, ui) {
      var dragged_item = ui.draggable;
      var drop_target = $(this);
      var drag_target_id = dragged_item.data('target-id');
      var drop_target_id = drop_target.data('target-id');
      var extra = $(this).data('extra');

      var drop_container = drop_target.closest('.droppable');

      $.ajax({
        type: 'post',
        data: {drag_target_id: drag_target_id, drop_target_id: drop_target_id, extra: extra},
        url: drop_container.data('action-url'),
        dataType: 'script'
      });
    }
  });
}