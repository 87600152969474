export function more_options() {
  $('body').on('click', '.more-options', function(e) {
    e.preventDefault();
  });

  $('body').on('click', '.more-options, .toggle-help', function(e) {
    $(this).next().toggle();

    $(this).attr('aria-expanded', function (_, attr) {
      return attr == 'true' ? 'false' : 'true'
    });

    var caret = $(this).find('.fa-caret-down');
    if (caret.length > 0) {
      caret.addClass('fa-caret-up').removeClass('fa-caret-down');
    } else {
      caret = $(this).find('.fa-caret-up');
      caret.addClass('fa-caret-down').removeClass('fa-caret-up');
    }
  
    $('.admin table').not('.floatThead-table').trigger('reflow');
    FoundationMethods.initialize();
    e.preventDefault();
  });
}
