export function page_state() {
  var current_full_location;
  $('body').on('click', '.pagination a[data-remote="true"]', function(e) {
    e.preventDefault();
  
    var url = $(this).attr("href");
    $.ajax({
      type: 'get',
      url: url,
      dataType: 'script',
      success: function(data) {
        window.history.pushState(url, '', url);
        current_full_location = location.pathname + location.search;;
      }
    });
  
    return false;
  });

  $(window).on('popstate', function(event) {
    var new_full_location = location.pathname + location.search;
    if (event.originalEvent.state && current_full_location != new_full_location) {
      $.ajax({
        type: 'get',
        url: event.originalEvent.state,
        dataType: 'script'
      });
  
      current_full_location = new_full_location;
    }
  });

  $('body').on('click', '.doc-navigation a', function(e) {
    e.preventDefault();
  
    var url = '/admin/help/documentation?id=' + $(this).attr("link");
    window.history.pushState(url, '', url);
    current_full_location = location.pathname + location.search;
  
    return false;
  });
}

