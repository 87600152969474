export function copy_to_clipboard() {
  var clipboard = new ClipboardJS('.copy-button');

  clipboard.on('success', function(e) {
    $(e.trigger).html('<i class="far fa-check"></i> Copied');
    $(e.trigger).css('cursor', 'auto');
  
    $(e.trigger).hover(function() {
      $(this).css("background-color", "#188200");
    });
  });
}
