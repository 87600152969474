export function toggle_tags_handler() {
  $(document).on('click', '.toggle-tags', function(e) {
    var $more_link = '<a href="#" class="toggle-tags text-xs !font-normal" aria-expanded="false">More</a>';
    var $less_link = '<a href="#" class="toggle-tags text-xs !font-normal" aria-expanded="true">Less</a>';
    e.preventDefault();
  
    let $parent = $(this).closest('.tags');
  
    $parent.find('.hidden').toggle();
    $(this).attr('aria-expanded') == 'false' ? $(this).hide() :  $parent.find('.with-more-link .toggle-tags').show();
  });
}