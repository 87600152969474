export function display_upload() {
  function display_filename(input) {
    if (input.files && input.files[0]) {
      var file = input.files[0];
      var label = $("label[for='"+$(input).attr('id')+"'].button");
      label.siblings('.filename').html(file.name);
    }
  }
  
  $('body').on('change', "input[type='file']", function() {
    display_filename(this);
  });
  
  $('body').on("click", ".invisible-button", function() {
    $(this).siblings('label').trigger('click');
  });
}
