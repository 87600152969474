export function initialize_tabs() {
  var fragment = window.location.hash;

  if ($('.tabs').length > 0) {
    new Foundation.Tabs($('.tabs'));

    // This keeps an array of tab IDs that have been loaded already
    // so that switching to loaded tabs don't incur an additional AJAX call
    $('.tabs').data('loaded-tabs', []);

    // This is necessary for the dynamic tabs
    if (fragment.length == 0) {
      let $first_tab = $('.tabs .tabs-title').first();
      $first_tab.click();
    }

    var url = action_url($('#main-tabs.tabs .is-active'));
    if (fragment == '') {
      load_url_in_pane(url, pane_id($('.tabs').first()));
    } else {
      load_url_in_pane(url, fragment);
    }

    // Listeners
    $('.tabs').on('change.zf.tabs', function (event, tab) {
      if (!tab_loaded(tab))
        load_url_in_pane(action_url(tab), pane_id(tab));
    });
  }
}

function pane_id(elem) {
  return $(elem).find('a').attr('href');
}

function action_url(elem) {
  return $(elem).find('a').data('action-url');
}

function tab_loaded(elem) {
  return $('.tabs').data('loaded-tabs').includes(pane_id(elem));
}

//
function load_url_in_pane(url, dest_pane_id) {
  if (url) {
    var $pane = $(dest_pane_id);

    // Get URL parameters
    var pairs = location.search.slice(1).split('&');
    var hash = {};
    pairs.forEach(function(pair) {
      pair = pair.split('=');
      hash[pair[0]] = decodeURIComponent(pair[1] || '');
    });

    $.ajax({
      dataType: 'html',
      url: url,
      data: hash,
      success: function(data) {
        $pane.html(data);
        $('.tabs').data('loaded-tabs').push(dest_pane_id);
      }
    })
  }
}
