// called inside update_unit.js, reinitializes the js when a row is updated

import TruncateElement from "./truncate_element";
export default class ReinitializeTruncate {
  // static = call a method as a class method (without creating an object instance)
  static initialize(tr) {
    if (!!tr) {
      if (!!tr.find('.truncate-container')) {
        TruncateElement.initialize();
      }
    }
  }
}