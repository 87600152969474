$(function() {
  $('body').on('click', '.load-month-button', function(e) {
    e.preventDefault();

    $(this).closest('.simple-calendar').parent().load($(this).attr('data-href'));
  })

  // Removes the custom css. We might not need this code once the tailwind refactor is finished but will keep for now
  $('.event-title-container').removeClass('has-tip');
});
