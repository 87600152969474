export function focus_on_open_modal() {
  const $modal = $('#modal-box');
  const $modal_2 = $('#modal-box-2');

  function get_first_focusable_element(modal) {
    // Get all the focusable elements inside a modal
    let inputs = modal.find('a[href], area[href], input, select, textarea, button, iframe, object, embed, [tabindex], [contenteditable]')
    .not('[tabindex=-1], [disabled], :hidden');

    return inputs[0]
  }

  window.addEventListener('focus', function(e) {
    const is_modal_open = $modal && $modal.is(':visible'); 
    const is_modal_2_open = $modal_2 && $modal.is(':visible');

    if (is_modal_open || is_modal_2_open) {
      // Focus is not inside the modal
      if ($(e.target).closest('#modal-box').length === 0) {
        get_first_focusable_element($modal).focus();
      } else if ($(e.target).closest('#modal-box-2').length === 0) {
        get_first_focusable_element($modal_2).focus();
      }
    }
  });
}