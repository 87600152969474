export function rerank_table() {
  $('.items-container .ranked-section').each(function() {
    $(this).find('tr:not(.almost-transparent) .rank, .grid-cell:not(.almost-transparent) .rank, .listing:not(.almost-transparent) .rank').each(function(i) {
      $(this).html(i + 1);
    });
  });

  // Aug 2023: For the new user application ranking ui we will add the # sign
  $('.application-page .ranked-section').each(function() {
    $(this).find('.grid-cell:not(.almost-transparent) .rank').each(function(i) {
      $(this).html('#' + (i + 1));
    });
  });
}

export function update_function(e, ui, action_url_elem) {
  var item_id = ui.item.data('target-id'); // ui.item is an element from items; should usually be the tr
  var program_id = ui.item.data('target-program-id');
  var position = ui.item.index();

  if ($.isNumeric(program_id)) {
    position = ui.item.index('[data-target-program-id="' + program_id + '"]');
  }

  var container = ui.item.parents(action_url_elem).first();

  $.ajax({
    type: 'post',
    url: container.data('action-url'),
    dataType: 'script',
    // XXX_position => XXX is the column name expected in ranked-model
    data: { id: item_id, items: { position_position: position } }
  });
}

export function sort_elements(container_selector, items_selector, attributes, orderings) {
  const container = $(container_selector)
  const items = container.find(items_selector)

  function recursively_sort(a, b, attributes_, orderings_) {
    const attr = attributes_[0]
    const order = orderings_[0]

    const a_attr = $(a).find('[data-' + attr + ']').data(attr)
    const b_attr = $(b).find('[data-' + attr + ']').data(attr)

    if (a_attr > b_attr) {
      return (order == "asc") ? 1 : -1
    } else if (a_attr < b_attr) {
      return (order == "asc") ? -1 : 1
    } else {
      if (attributes_.length === 1) {
        return 0
      } else {
        return recursively_sort(a, b, attributes_.slice(1), orderings_.slice(1))
      }
    }
  }

  function sort_by_attributes(attributes_, orderings_) {
    return function(a, b) {
      return recursively_sort(a, b, attributes_, orderings_)
    }
  }

  items.sort(sort_by_attributes(attributes, orderings))
  container.append(items)
}
