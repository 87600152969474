// Couldn't find an easy way to use stimulus for this.
// The survey-matrix allows you to add and remove columns.
// Not sure how to reference the radio buttons in the table cells
// using stimulus targets if they’re dynamically generated.
// Plus, for the case of unique, changing a value in one radio button
// needs to clear the radio button in all other ones in that column.
// I’m not sure how stimulus targets reach outside of themselves.
// So don't try to convert this to a stimulus controller.

export function initialize_survey_matrix() {
  $('body').on('click', '.survey-matrix.unique input[type="radio"]', function() {
    var $matrix = $(this).closest('.survey-matrix.unique');
    var val = $(this).val();
    var id = $(this).attr('id');
    $matrix.find('input[type="radio"][value="'+val+'"][id!="'+id+'"]').prop('checked', false);
  });
}
